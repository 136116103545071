import React from 'react';
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { Layout, Container } from 'layouts';
import Constants from '../../static/Constants';

const ErrorPage = center => {
  const helmetLink = `${Constants.appUrl}/404`;
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Investment Promotion Portal 404</title>
        <link rel="canonical" href={helmetLink} />
      </Helmet>
      <Container center={center}>
        <h1>Woops, something went wrong.</h1>
        <h3>This page does not exist or is no longer reachable.</h3>
        <h3>
          You can return to the <Link to="/">Homepage</Link>.
        </h3>
      </Container>
    </Layout>
  );
};

export default ErrorPage;

ErrorPage.propTypes = {
  center: PropTypes.object,
};
